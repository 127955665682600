window.pages = [];

$('html').removeClass('no-js');

$('document').ready(function () {
	var $body = $('body');
	var toggle = $('body > .container > header .top button.menu');

	$('body > .container > section.menu').navigation({
		toggle: toggle
	});

	// Load hero image first!
	if ($('body.contact, body.galleries_index, body.about, body.galleries').length) {
		if ($($('body > .container > .page > .hero .image')).length) {
			responsiveImages.preloadBackground.call($('body > .container > .page > .hero .image'), function () {
				$('body > .container > .page > .hero').addClass('loaded');
			});
		}
	}

	if($('body.intro .hero').length) {
		window.pages.intro.init();
	}

	window.pages.gallery.init();

	if ($('body.gallery .gallery .slides').length) {
		window.pages.gallery.createGallery();
	}

	$('body.about .profile .scene, body.contact .invitation .scene').scrollAction({
		"offsetStart": function (element) {
			return $(element).offset().top + $(element).outerHeight()*(1/2) - $(window).height();
		},
		"classOffset": 'animate',
		"permanent": true
	});

	$('body.contact, body.about').find('main > section').scrollAction({
		"offsetStart": function (element) {
			return $(element).offset().top - $(window).height() + 20;
		},
		"classOffset": 'animate',
		"permanent": true
	});

	window.footer.init();

	// Sticky navigation menu
	$('body.contact, body.galleries_index, body.about, body.galleries').scrollAction({
		"classUp": 'scroll-up',
		"classDown": 'scroll-down',
		"directionThreshold": 100,
		"offsetStart": function () {
			if ($body.hasClass('galleries_index') || $body.hasClass('galleries')) {
				return $('section.page').css('padding-top');
			}
			return 50;
		},
		"classOffset": 'sticky-nav'
	});
});

/*
 * namespace smooth navigation
 */
(function (window, undefined) {
	var options = {
		duration: 500
	};

	function init(settings) {
		options = $.extend( {}, options, settings );
		$('a[data-smooth]').on('click', navigate);
	}

	function navigate(event) {
		event.preventDefault();
		var href = $(this).attr('href');
		$('body').addClass('loading');
		setTimeout(function() {
			window.location.href = href;
		}, options.duration);
		return false;
	}

	// Public functions
	window.smooth_navigation = {
		init: init
	};
})(window);

/*
 * namespace page intro
 */
(function (window, undefined) {
	function init() {
		$('body.intro').addClass('loading');

		responsiveImages.preloadBackground.call($('body.intro .hero .slides .slide:first-child'), function () {
			$('body.intro .hero .slides').gallery({
				'auto': true,
				'duration': 5000,
				'onChange': function (gallery, index, length) {
					if($(gallery).find('.slide').eq(index).length) responsiveImages.preloadBackground.call($(gallery).find('.slide').eq(index));
					if($(gallery).find('.slide').eq(index+1).length) responsiveImages.preloadBackground.call($(gallery).find('.slide').eq(index+1));
				},
				'skipLoopIteration': function (gallery, index) {
					var slideCount = $(gallery).find('.slide').length;
					if($(gallery).find('.slide').eq((index+1) % slideCount).length) {
						if ($(gallery).find('.slide').eq((index + 1) % slideCount).hasClass('loaded')) {
							return false;
						}
					}

					return true;
				}
			});

			$('body.intro').addClass('loaded').removeClass('loading');
			$('body.intro > .container > section.page .hero').addClass('loaded');
			$('body.intro > .container > header .logo').addClass('loaded-animation').one('transitionend', function () {
				$(this).removeClass('loaded-animation');
			});
		});
	}

	// Public functions
	window.pages.intro = {
		init: init
	};
})(window);

/*
 * namespace page gallery
 */
(function (window, undefined) {
	var mediaSliderView = '(min-width: 751px) and (min-height: 520px) and (orientation: landscape)';

	function init() {
		$('body.gallery main nav').scrollAction({
			"offsetStart": function () {
				return $('.container > section.page').css('padding-top');
			},
			"classOffset": 'sticky'
		});

		$('body.galleries .galleries .item.gallery').scrollAction({
			"offsetStart": function (element) {
				return $(element).offset().top - $(window).height() * 2;
			},
			"offsetEnd": function (element) {
				return $(element).offset().top + $(element).outerHeight();
			},
			"actionInside": function (element) {
				var $element = $(element);
				var $image = $element.find('.image');

				if ($image.hasClass('loading') || $image.hasClass('loaded') || $image.hasClass('loading-error')) return false;
				$image.addClass('loading');

				var url = responsiveImages.select($image.data('src'), $image.data('srcset'));

				responsiveImages.preload(url, function () {
					$element.addClass('loaded');
					$image.addClass('loaded').removeClass('loading');

					var element = {top: $image.offset().top, bottom: $image.offset().top + $image.outerHeight()};
					var viewport = {top: $(window).scrollTop(), bottom: $(window).scrollTop() + $(window).height()};

					if (element.top < viewport.bottom && element.bottom > viewport.top) {
						$image.addClass('visible');
					}
				}, function () {
					$element.addClass('loading-error');
					$image.addClass('loading-error').removeClass('loading');;
				});
			}
		});

		$('body.galleries .galleries .item.gallery').scrollAction({
			"offsetStart": function (element) {
				return $(element).offset().top - $(window).height();
			},
			"offsetEnd": function (element) {
				return $(element).offset().top + $(element).outerHeight();
			},
			"actionInside": function (element) {
				if ($(element).find('.image').hasClass('visible')) return false;

				$(element).find('.image.loaded').addClass('visible');
			}
		});

		$('body.gallery nav a.back').on('click', function (event) {
			if (document.referrer.indexOf(location.protocol + "//" + location.host) === 0) {
				event.preventDefault();
				history.back();
			}
		});

		var infoPrevScrollPosition = 0;
		var closeInfo = function () {
			$('body.gallery section.info').scrollTop(0).removeClass('show');
			$('body.gallery').removeClass('info-show');
			$(window).scrollTo(infoPrevScrollPosition);
		}

		$(window).on('keyup', function (event) {
			if ($('body').hasClass('gallery') && !$('body').hasClass('info-show')) {
				if (event.which == 27) {
					$('body.gallery nav a.back').trigger('click');
				}
			}
		});

		$('body.gallery nav button.info').on('click', function (event) {
			event.preventDefault();
			infoPrevScrollPosition = $(window).scrollTop();
			$('body.gallery section.info').addClass('show');
			$('body.gallery').addClass('info-show');
			$(document).one('keyup', function (event) {
				if (event.which == 27) {
					event.stopImmediatePropagation();
					closeInfo();
				}
			});
		});

		$('body.gallery section.info button.close').on('click', function (event) {
			event.preventDefault();
			closeInfo();
		})
	}

	function createGallery () {
		$('body.gallery .gallery .slides .slide').scrollAction({
			"offsetStart": function (element) {
				return $(element).offset().top - $(window).height() * 2;
			},
			"offsetEnd": function (element) {
				return $(element).offset().top + $(element).outerHeight();
			},
			"actionInside": function (element) {
				var $element = $(element);
				var $image = $element.find('img');

				if (matchMedia(mediaSliderView).matches) return false;
				if ($element.hasClass('loading') || $element.hasClass('loaded') || $element.hasClass('loading-error')) return false;

				$element.addClass('loading');

				responsiveImages.loadScrset.call($image, function (element) {
					var image = $(element);
					image.parents('.slide').addClass('loaded').removeClass('loading');
				}, function (element) {
					var image = $(element);
					image.parents('.slide').addClass('loading-error').removeClass('loading');
				});
			}
		});

		$('body.gallery .gallery .slides').gallery({
			"onCreate": function (gallery, index) {
				$(window).on('popstate', function (event) {
					if (history.state) gallery.change(history.state.galleryIndex);
				});
			},
			"start": window.config.gallery.current,
			"onChange": function (gallery, index, length) {
				//Update index and length indicators in UI
				$('body.gallery .page main > nav .index > span').text(((String)(index + 1)).padInt("0", 2));
				$('body.gallery .page main > nav .length > span').text(((String)(length)).padInt("0", 2));

				//Update browser URL
				var data = {galleryIndex: index};
				var url = window.config.baseUrl + "galleries/" + window.config.gallery.theme + "/" + window.config.gallery.name + "/" + (index + 1);
				history.replaceState(data, "", url);

				//Load and preload next images
				var slide;

				if (!matchMedia(mediaSliderView).matches) return false;

				var loadImage = function (i, done_callback) {
					slide = $(gallery).find('.slide').eq((index+i)%length);

					if (slide.hasClass('loading') || slide.hasClass('loaded') || slide.hasClass('loading-error')) {
						if (typeof(done_callback) == 'function') done_callback();
						return false;
					}

					slide.addClass('loading');

					if (slide.length) {
						responsiveImages.loadScrset.call(slide.find('img'), function (element) {
							var image = $(element);
							image.parents('.slide').addClass('loaded').removeClass('loading');
						}, function (element) {
							var image = $(element);
							image.parents('.slide').addClass('loading-error').removeClass('loading');
						}, function (element) {
							if (typeof(done_callback) == 'function') done_callback();
						});
					}
				}

				loadImage(0, function () {
					for (var i = 1; i <= 2; i++) {
						loadImage(i);
					}
				});
			}
		});
	}

	// Public functions
	window.pages.gallery = {
		init: init,
		createGallery: createGallery
	};
})(window);

/*
 * namespace footer
 */
(function (window, undefined) {
	function init() {
		var footerCounting = false;
		$('body > .container > footer .numbers ul').scrollAction({
			"offsetStart": function (element) {
				return $(element).offset().top - $(window).height();
			},
			"actionInside": function (element) {
				if (footerCounting) return;

				footerCounting = true;

				$('.number .count').each(function () {
					var $this = $(this);
					$this.css({
						'min-width': $this.width()
					})

					var number = $this.text().replace(/\./g, '');
					var comma_separator_number_step = $.animateNumber.numberStepFactories.separator('.');
					$(this).animateNumber({
						number: number,
						numberStep: comma_separator_number_step
					}, 1500);
				});
			},
			"once": true
		});

		$('footer .to-top').on('click', function (event) {
			event.preventDefault();
			$.scrollTo(0, 500);
			$('body > .container header .logo a').focus();
		});
	}

	// Public functions
	window.footer = {
		init: init
	};
})(window);

/*
 * namespace responsive images
 */
(function (window, undefined) {
	var preload = function (url, callback, error_callback, always_callback) {
		var img = new Image();
		img.src = url;
		img.addEventListener('load', function () {
			if (typeof(callback) == 'function') callback();
			if (typeof(always_callback) == 'function') always_callback();
		});
		img.addEventListener('error', function () {
			if (typeof(error_callback) == 'function') error_callback();
			if (typeof(always_callback) == 'function') always_callback();
		});
	};

	var loadSrcset = function(callback, error_callback, always_callback) {
		var $element = $(this);

		$element.on('load', function () {
			if (typeof(callback) == 'function') callback(this);
			if (typeof(always_callback) == 'function') always_callback(this);
		});
		$element.on('error', function () {
			if (typeof(error_callback) == 'function') error_callback(this);
			if (typeof(always_callback) == 'function') always_callback(this);
		});

		$element.prop('srcset', $element.data('srcset'));
		$element.prop('sizes', $element.data('sizes'));
	}

	var select = function (src, srcset) {
		var image = src;

		for (var i = 0; i < srcset.length; i++) {
			if (window.matchMedia(srcset[i].condition).matches) image = srcset[i].src;
		}

		return image;
	};

	var preloadBackground = function (callback) {
		var $this = $(this);
		if ($this.hasClass('loading') || $this.hasClass('loaded') || $this.hasClass('loading-error')) return false;
		$this.addClass('loading');

		var url = responsiveImages.select($this.data('src'), $this.data('srcset'));

		responsiveImages.preload(url, function () {
			$this.addClass('loaded').removeClass('loading');
		}, function () {
			$this.addClass('loading-error').removeClass('loading');
		}, callback);
	};

	// Public functions
	window.responsiveImages = {
		preload: preload,
		select: select,
		preloadBackground: preloadBackground,
		loadScrset: loadSrcset
	};
})(window);
